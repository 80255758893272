.login-form {
    input {
        padding: 11px 20px 9px;
        font-size: 16px;
        outline: none;
        height: 55px;
        color: #535353;
        border-radius: 3px;
        border: 1px solid transparent;
        background: #f6f6f6;
    }
    .form-group {
        position: relative;
        margin-bottom: 1.5rem;
        .error-msg {
            font-family: "Inter", sans-serif;
            color: #d32f2f;
            font-size: 0.87rem;
            position: absolute;
        }
    }
}

p {
    &.error-msg {
        font-family: "Inter", sans-serif;
        color: #d32f2f;
        font-size: 13px;
        position: absolute;
        line-height: 16px;
    }
    &.mobile-num {
        top: 38px;
    }
}

.ant-form-item {
    &.invalid {
        .ant-form-item-control {
            .ant-select-selector {
                border: 1px solid #d32f2f;
                .ant-select-selection-placeholder {
                    color: #d32f2f;
                }
                &:hover {
                    border: 1px solid #d32f2f;
                }
            }
            .ant-input {
                border: 1px solid #d32f2f;
                &::placeholder {
                    color: #d32f2f;
                }
            }
        }
    }
}

.ant-input {
    &::placeholder {
        font-size: 0.857rem;
        font-weight: 300;
    }
}

.ant-form-item  {
    .ant-form-item-label  {
       label {
        font-family: "Inter", sans-serif;
        font-size: .875rem;
        color: #1e293b;
        font-weight: 500;
       } 
    }
    .ant-input {
        font-family: "Inter", sans-serif;
        font-size: .875rem;
        padding: 8px 11px;
    }
    .ant-select {
        min-height: 39.6px;
    }
}

.form-label {
    font-size: 0.875rem;
    color: #1e293b;
    font-weight: 500;
    margin-bottom: 3px;
}
.form-control {
    border: 1px solid #ccc;
    min-height: 38px;
    font-size: 0.875rem;
    &::placeholder {
        color: #aeaeae;
        font-size: 0.875rem;
    }
}

a {
    &.otp-text {
        font-size: 12px;
        position: absolute;
        left: 15px;
        width: 100%;
        bottom: -22px;
        text-decoration: none;
        cursor: pointer;
        font-weight: 400;
    }
}



.css-b62m3t-container {
    .css-13cymwt-control {
        .css-1fdsijx-ValueContainer {
            .css-1jqq78o-placeholder {
                color: #aeaeae;
                font-size: 0.875rem;
            }
        }
    }
    .css-1nmdiq5-menu {
        z-index: 9999;
    }
}

.form-group {
    position: relative;
}

.form-check-input {
    width: 20px;
    height: 20px;
}

.datepicker-form {
    position: relative;
    button {
        border: 1px solid #ccc;
        min-height: 38px;
        font-size: 0.75rem;
        width: 100%;
        background: #fff;
        border-radius: 0.25rem;
        text-align: left;
        padding: 10px 13px;
        font-weight: 500;
        color: #4d556a;
        &:hover {
            border: 1px solid #a9a9a9;
        }
    }
    .calendar-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 18px;
        color: #4d556a;
        cursor: pointer;
        pointer-events: none;
    }
}

.css-1jqq78o-placeholder {
    font-size: 0.8rem;
}

.eye-icon-login {
    position: absolute;
    top: 17px;
    right: 12px;
    font-size: 22px;
    color: #838383;
    z-index: 999;
}

.eye-icon-User {
    position: absolute;
    top: 9px;
    right: 12px;
    font-size: 22px;
    color: #838383;
    z-index: 999;
}

.new-password {
    top: 51px !important;
    right: 41px !important;
    color: #262626 !important;
}

.confirm-password {
    top: 150px !important;
    right: 41px !important;
    color: #262626 !important;
}

// .phone-input-field {
//     height: 38px;
//     // position: absolute;
//     background: #f8f9fa;
//     border: 1px solid #ccc;
//     width: 100%;
//     font-size: 13.5px;
//     font-weight: 400;
//     position: relative;
//     .css-b62m3t-container {
//         position: absolute;
//         right: -2px;
//         width: 23px;
//         background: #f8f9fa;
//         .css-13cymwt-control {
//             border-left: 0;
//             border-top-left-radius: 0;
//             border-bottom-left-radius: 0;
//             .css-1fdsijx-ValueContainer {
//                 display: none;
//             }
//             .css-1hb7zxy-IndicatorsContainer {
//                 .css-1u9des2-indicatorSeparator {
//                     display: none;
//                 }
//                 .css-1xc3v61-indicatorContainer {
//                     padding: 0;
//                 }
//             }
//         }
//         .css-1nmdiq5-menu {
//             left: 0px;
//             top: 32px;
//             min-width: 267px;
//             text-align: left;
//             font-size: 12px;
//             @media screen and (max-width: 600px) {
//                 right: 0;
//                 left: auto;
//             }
//         }
//     }
// }

.pl-0 {
    padding-left: 0 !important;
}
.pr-0 {
    padding-right: 0 !important;
}
.w-17 {
    width: 17% !important; 
}

@media screen and (max-width: 600px) {
    .w-17 {
        width: 100% !important;
    }
    .pl-0 {
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
    }
    .pr-0 {
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
    }
}

.cc-select {
    font-size: 0.8rem;
    & > div {
        &:last-child {
            width: 200px;
        }
    }
    &.main-form {
        position: absolute !important;
    left: 0px;
    }
}

.mobile-number {
    padding-left: 104px;
}


.cc-select > span + div {
    width: 93px !important;
}
.phone-input-field {
    position: absolute;
    font-size: 0.8rem;
    background-color: #fff;
    border-radius: 4px;
    height: 33px;
    border-color: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    width: 51px;
    padding: 0;
    padding-left: 8px;
    margin-top: 2px;
    margin-left: 1px;
}